<template>
  <div class="container">
    <div class="container__header header">
      <div class="header__search">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.0621 11.8375C13.161 10.6856 13.8357 9.12542 13.8357 7.40771C13.8357 3.86263 10.9618 0.98877 7.41674 0.98877C3.87166 0.98877 0.997803 3.86263 0.997803 7.40771C0.997803 10.9528 3.87166 13.8266 7.41674 13.8266C9.2441 13.8266 10.8931 13.063 12.0621 11.8375ZM12.0621 11.8375L16.775 16.5504" stroke="#E0E0E0" stroke-width="1.36259" stroke-linecap="round"/>
        </svg>
        <input class="header__search__input" placeholder="Поиск по имени" v-model="filteredString">
      </div>
      <button
          @click="$router.push({name: 'ManagersCreate'})"
          class="header__button">
        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M4.45084 0C1.99271 0 0 1.99271 0 4.45084V15.5534C0 18.0115 1.99271 20.0042 4.45084 20.0042H15.5534C18.0115 20.0042 20.0042 18.0115 20.0042 15.5534V4.45084C20.0042 1.99271 18.0115 0 15.5534 0H4.45084ZM1.5 4.45084C1.5 2.82114 2.82114 1.5 4.45084 1.5H15.5534C17.1831 1.5 18.5042 2.82114 18.5042 4.45084V15.5534C18.5042 17.1831 17.1831 18.5042 15.5534 18.5042H4.45084C2.82114 18.5042 1.5 17.1831 1.5 15.5534V4.45084ZM10.7517 4.45108C10.7517 4.03686 10.4159 3.70108 10.0017 3.70108C9.5875 3.70108 9.25171 4.03686 9.25171 4.45108V9.25233H4.45045C4.03624 9.25233 3.70045 9.58812 3.70045 10.0023C3.70045 10.4165 4.03624 10.7523 4.45045 10.7523H9.25171V15.5536C9.25171 15.9678 9.5875 16.3036 10.0017 16.3036C10.4159 16.3036 10.7517 15.9678 10.7517 15.5536V10.7523H15.553C15.9672 10.7523 16.303 10.4165 16.303 10.0023C16.303 9.58812 15.9672 9.25233 15.553 9.25233H10.7517V4.45108Z" fill="#F8F8F8"/>
        </svg>
        Добавить менеджера</button>
    </div>
    <div class="container__body">
      <div class="container__table table">
        <div class="table__header">
          <p class="table__header__text table__header__text_created">Дата регистрации</p>
          <p class="table__header__text table__header__text_name">Имя Фамилия</p>
          <p class="table__header__text table__header__text_phone">Номер телефона</p>
          <p class="table__header__text table__header__text_count">Кол-во учреждений</p>
          <p class="table__header__text table__header__text_profit">Прибыль</p>
        </div>
        <div class="table__body">
          <ManagerTableItem
              class="table__body__item"
              v-for="manager of filteredManagers"
              :manager="manager"
              :key="manager.id"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ManagerTableItem from "@/components/Manager/ManagerTableItem";

export default {
  name: "ManagersList",
  components: {ManagerTableItem},
  data(){
    return {
      managers: [],
      filteredString: ""
    }
  },
  computed:{
    filteredManagers(){
      if (this.filteredString.length === 0){
        return this.managers
      } else {
        return this.managers.filter(manager => {
          return manager.fullName.toLowerCase().includes(this.filteredString.toLowerCase())
        })
      }
    }
  },
  methods:{
    async getManagers(){
      const res =  await this.$axios.get("managers")
      return res.data
    },
  },
  async mounted() {
    this.managers = [...await this.getManagers()];
  }
}
</script>

<style scoped lang="scss">
.container{
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  width: 961px;
  margin-top: 40px;

  &__header{

  }

  &__body{
    margin-top: 12px;
  }
}


.table{
  width: 961px;
  height: 705px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  background: #FFFFFF;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
  border-radius: 4px;

  &__header{
    display: flex;
    margin-top: 22px;
    margin-left: 16px;

    &__text{
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      color: #BBBBBB;

      &_name{
        margin-left: 35px;
      }

      &_phone{
        margin-left: 62px;
      }

      &_count{
        margin-left: 44px;
      }

      &_profit{
        margin-left: 23px;
      }
    }
  }

  &__body{
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    margin-top: 4px;
  }

  &__item{
    display: flex;
  }
}



.header{
  display: flex;
  align-items: center;

  &__search{
    display: flex;
    width: 673px;
    height: 36px;
    background: #FFFFFF;
    border-radius: 4px;
    align-items: center;

    svg{
      margin-left: 10px;
    }

    &__input{
      border: 0;
      outline: 0;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: black;
      height: 36px;
      width: calc(100% - 50px);
      margin-left: 10px;

      &::placeholder{

        color: #E0E0E0;
      }
    }
  }

  &__button{
    margin-left: 60px;

    background: #6099FC;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    border: 0;
    outline: 0;

    width: 218px;
    height: 48px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */


    color: #F8F8F8;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    &:hover{
      opacity: .75;
    }


    svg{
      margin-right: 14px;
    }
  }
}
</style>